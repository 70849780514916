// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// Modules
const externalUserModule = require('../modules/external-user');
const navigationModule = require('../modules/navigation');
const inputModule = require('../modules/input');


/**
 * Action Handlers
 */

function createActionHandlers() {

	// Handle SSO error
	$(document).ready(() => {
		if (inputModule.getParameterByName('result') === 'classlink_error') {
			inputModule.showBlockAlert("We're having trouble signing into your ClassLink account. Please try again.");
		}
	});

	// Handler press form button
	$(document).keypress((e) => {
		if (e.keyCode === 13) {
			$('#formButton').click();
		}
	});

	// Handle click on action button
	$('#formButton').click(() => {
		externalUserModule.handleUserLogIn();
	});

	// Handle click on classlink button
	$('#classlink-button').click(() => {
		externalUserModule.handleClassLinkSignIn();
	});
}


/**
 * Input Handlers
 */

function createInputHandlers() {

	// Handle focus
	$('#email, #pass').focus(() => {
		inputModule.removeBlockAlert();
	});

	// Handle first name blur
	$('#email').blur(() => {
		inputModule.removeAlert('emailLabel', 'Your Email', true);
	});

	// Handle last name blur
	$('#pass').blur(() => {
		inputModule.removeAlert('passLabel', 'Your Password', true);
	});

	// Handle first name key down
	$('#email').keydown(() => {
		inputModule.removeAlert('emailLabel', 'Your Email', true);
	});

	// Handle last name key down
	$('#pass').keydown(() => {
		inputModule.removeAlert('passLabel', 'Your Password', true);
	});
}


/**
 * Init
 */

// Initialize navigation
navigationModule.initialize();

// Handler external user
externalUserModule.handleExternalUser(false, true);

// Create action handlers
createActionHandlers();

// Create input handlers
createInputHandlers();
